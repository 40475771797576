import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

const ViewReceipts = () => {
    const { empid } = useParams();
    const [employee, setEmployee] = useState({})

    const apiUrl = process.env.REACT_APP_URL;
    const Token = localStorage.getItem("Token");

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const day = date.getDate().toString().padStart(2, '0');
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    useEffect(() => {
        async function getEmp() {
            let response = await fetch(`${apiUrl}/payment/getPaymentRecipt?paymentId=${empid}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${Token}`
                },
            });
            response = await response.json();

            if (response.status === "success") {
                const formattedData = {
                    ...response.data,
                    formattedDate: response.data.createdAt ? formatDateTime(response.data.createdAt) : null,
                    formattedDate2: response.data.dateOfPayment ? formatDateTime(response.data.dateOfPayment) : null,
                };
                setEmployee(formattedData);
            } else {
                // Handle error
                console.error("Error fetching payment receipt:", response.message);
            }
        }
        getEmp();
    }, []);

    return (
        <div style={{ background: "#f7f7f7", margin: 0, padding: "50px 0" }}>
            <table
                align="center"
                width={700}
                border={0}
                cellSpacing={0}
                cellPadding={0}
                style={{
                    background: "#fff",
                    fontFamily: '"Poppins", sans-serif',
                    fontSize: 13,
                    borderRadius: 15
                }}
            >
                <tbody>
                    <tr>
                        <td colSpan={3} style={{ height: 20 }} />
                    </tr>
                    <tr>
                        <td align="center" colSpan={3}>
                            <a href="/">
                                <img alt="company logo" border={0} width={150} src="https://amrealty.webkype.com/assets/img/brand/logo.png" />
                            </a>
                            <br />
                            <p>
                                RECEIPT NO: <b>{employee.receiptNo}</b>
                            </p>
                            <h3>Payment Receipt</h3>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3} style={{ height: 50 }} />
                    </tr>
                    <tr>
                        <td style={{ width: 30 }} />
                        <td>
                            <table
                                align="center"
                                width="100%"
                                border={0}
                                cellSpacing={0}
                                cellPadding={0}
                            >
                                <tbody>
                                    <tr>
                                        <td >
                                            <p>
                                                Received with thanks from Sh./Smt./M/s{" "}
                                                <b><span style={{ color: 'blue' }}>{employee.name}</span></b> having address at <span style={{ color: 'blue' }}>{employee.address}</span>.{" "}
                                                <b>Mobile :- <span style={{ color: 'blue' }}>{employee.mobileNo}</span></b> a sum of Rs :-{' '}
                                                <b><span style={{ color: 'blue' }}>{employee.Amount}</span> ({employee.amountWord})</b>, on account of
                                                booking/part/payment for Unit No F-<span style={{ color: 'blue' }}>{employee.UnitNo}</span> of{" "}
                                                <span style={{ color: 'blue' }}>{employee.scheme}</span> under Scheme{' '}
                                                
                                                    <span style={{ color: 'blue' }}>{employee.scheme}</span>
                                                {' '}
                                                under Project{' '}
                                               
                                                    <span style={{ color: 'blue' }}>{employee.project}</span>
                                                {' '}
                                                at # on <span style={{ color: 'blue' }}>{employee.formattedDate}</span> by <span style={{ color: 'blue' }}>{employee.methodOfPayment}</span> Method.{employee.methodOfPayment === 'Online' && employee.transactionId && (
                                                    <>
                                                        <b>Transaction Id: {employee.transactionId}</b>,{" "}
                                                        {/* <b>Bank Name: {employee.bankName}</b> */}
                                                    </>
                                                )}
                                                {employee.methodOfPayment === 'Cheque' && employee.chequeNo && (
                                                    <>
                                                        <b> Cheque Number: {employee.chequeNo}</b>,{" "}
                                                        <b>Cheque Date: {employee.formattedDate2}</b>
                                                    </>
                                                )}


                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 50 }} />
                                    </tr>
                                    <tr>
                                        <td align="right">
                                            <p>
                                                <b>From AMRS</b>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ height: 150 }} />
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <p>
                                                <b>
                                                    This is a computer-generated receipt, hence requires
                                                    no signature
                                                </b>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td style={{ width: 30 }} />
                    </tr>
                    <tr>
                        <td colSpan={3} style={{ height: 20 }} />
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ViewReceipts;
