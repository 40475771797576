import React, { useState, useEffect } from 'react';
import TopHeader from '../Components/TopHeader';
import Prince from '../Components/Prince';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';


const ManageEoi = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [toggleStates, setToggleStates] = useState({});
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const Token = localStorage.getItem("Token");
    const apiUrl = process.env.REACT_APP_URL;

    const loadContent = (id) => {
        navigate(`/project-edit/${id}`);
    };


    // Function to handle toggle action
    const handleToggle = (userId) => {
        setToggleStates((prevStates) => {
            const newStates = { ...prevStates, [userId]: !prevStates[userId] };
            localStorage.setItem('toggleStates', JSON.stringify(newStates));
            return newStates;
        });
    };

    // Retrieve toggle states from local storage when the component mounts
    useEffect(() => {
        const savedToggleStates = localStorage.getItem('toggleStates');
        if (savedToggleStates) {
            setToggleStates(JSON.parse(savedToggleStates));
        }
    }, []);

    const deleteContent = (id) => {
        fetch(`${apiUrl}/project/deleteProject/` + id, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${Token}`,
            }
        })
            .then((res) => {
                if (res.ok) {
                    fetchDataFromApi();
                    toast.success("Project deleted successfully");
                } else {
                    throw new Error('Failed to delete');
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const formatDateTimes = (dateTimeString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateTimeString);
        return date.toLocaleString('en-IN', options);
    };

    const fetchDataFromApi = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/project/getAllProject?isEOI=true`, {
                headers: {
                    'Authorization': `Bearer ${Token}`
                }
            });
            const data = await response.json();

            if (data.status === 'success' && Array.isArray(data.data)) {
                const formattedData = data.data.map(item => ({
                    ...item,
                    createdAt: item.createdAt ? formatDateTimes(item.createdAt) : '',
                }));
                setUsers(formattedData);
            } else {
                console.error('API response error:', data.message || 'Data array not found');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };


    useEffect(() => {
        fetchDataFromApi();
    }, []);

    const filteredUsers = users.filter((user) => {
        const searchLowerCase = search.toLowerCase();
        const fullNameLowerCase = user.projectName.toLowerCase();
        return user.id.toString().includes(searchLowerCase) || fullNameLowerCase.includes(searchLowerCase);
    });


    const keyframes = `
    @keyframes bounce {
      0%, 100% {
        transform: scale(0.9);
        opacity: 0.7;
      }
      50% {
        transform: scale(1.5);
        opacity: 1;
      }
    }
    `;

    const loaderStyles = {
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.3)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        },
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
        },
        dot: {
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            backgroundColor: '#3498db',
            animation: 'bounce 1.2s infinite ease-in-out',
        },
    };

    // Inject keyframes into the document
    const styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);


    useEffect(() => {
        const token = localStorage.getItem('Token');
        if (!token) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <>
            <div className="page">
                <TopHeader />
                <Prince />

                <div className="main-content pt-0">
                    <div className="main-container container-fluid">
                        <div className="inner-body">
                            <div className="page-header">
                                <div>
                                    <h2 className="main-content-title tx-24 mg-b-5"> EOI Project List</h2>

                                </div>
                                <div className="d-flex">
                                    <div className="justify-content-center">
                                        <Link
                                            to='/add-eoi-project'
                                            type="button"
                                            className="btn btn-primary my-2 btn-icon-text"
                                        >
                                            <i className="fe fe-plus me-2" /> Add EOI Projects
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body py-3">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input
                                                        type="search"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        aria-controls="example1"
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-sm">
                                <div className="col-lg-12">
                                    <div className="card custom-card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                {loading ? (
                                                    <div style={loaderStyles.overlay}>
                                                        <div style={loaderStyles.loaderContainer}>
                                                            <div style={loaderStyles.dot}></div>
                                                            <div style={{ ...loaderStyles.dot, animationDelay: '0.2s' }}></div>
                                                            <div style={{ ...loaderStyles.dot, animationDelay: '0.4s' }}></div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <table className="table table-striped table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th style={{ whiteSpace: 'nowrap' }}>Project Image</th>
                                                                <th style={{ whiteSpace: 'nowrap' }}>Privilege Premium Plan</th>
                                                                <th style={{ whiteSpace: 'nowrap' }}>Privilege Premium+ Plan</th>
                                                                <th>Project Information</th>
                                                                <th>Inventory</th>
                                                                <th>Date</th>
                                                                <th>Photo</th>
                                                                <th style={{ whiteSpace: 'nowrap' }}>Provisional Inventory</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {filteredUsers.map((user) => (
                                                                <tr key={user.id}>
                                                                    <td>{user.id}</td>
                                                                    <td>
                                                                        <img
                                                                            alt="avatar"
                                                                            src={user.banner || 'https://cdn1.iconfinder.com/data/icons/website-internet/48/website_-_male_user-512.png'}
                                                                            style={{ width: 60 }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        {Array.isArray(user.projectsubscription) && user.projectsubscription.length > 0 ? (
                                                                            user.projectsubscription
                                                                                .filter(subscription => subscription.isPremimum === false)
                                                                                .map((subscription, subIndex) => {
                                                                                    // Check if all fields are empty
                                                                                    const hasData = subscription.eoiType || subscription.eoiCode || subscription.eoiPrice || subscription.eoiTerms;

                                                                                    return (
                                                                                        <div key={subIndex}>
                                                                                            {hasData ? (
                                                                                                <>
                                                                                                    <strong>EOI Type:</strong> {subscription.eoiType || 'N/A'}<br />
                                                                                                    <strong>EOI Code:</strong> {subscription.eoiCode || 'N/A'}<br />
                                                                                                    <strong>EOI Price:</strong> {subscription.eoiPrice || 'N/A'}<br />
                                                                                                    <strong>EOI Terms:</strong> {subscription.eoiTerms || 'N/A'}<br />
                                                                                                    <br />
                                                                                                </>
                                                                                            ) : (
                                                                                                <div></div>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                        ) : (
                                                                            <div>No EOI details available</div>
                                                                        )}
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        {Array.isArray(user.projectsubscription) && user.projectsubscription.length > 0 ? (
                                                                            user.projectsubscription
                                                                                .filter(subscription => subscription.isPremimum === true)
                                                                                .map((subscription, subIndex) => {
                                                                                    // Check if all fields are empty
                                                                                    const hasData = subscription.eoiType || subscription.eoiCode || subscription.eoiPrice || subscription.eoiTerms;

                                                                                    return (
                                                                                        <div key={subIndex}>
                                                                                            {hasData ? (
                                                                                                <>
                                                                                                    <strong>EOI Type:</strong> {subscription.eoiType || 'N/A'}<br />
                                                                                                    <strong>EOI Code:</strong> {subscription.eoiCode || 'N/A'}<br />
                                                                                                    <strong>EOI Price:</strong> {subscription.eoiPrice || 'N/A'}<br />
                                                                                                    <strong>EOI Terms:</strong> {subscription.eoiTerms || 'N/A'}<br />
                                                                                                    <br />
                                                                                                </>
                                                                                            ) : (
                                                                                                <div></div>
                                                                                            )}
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                        ) : (
                                                                            <div>No EOI details available</div>
                                                                        )}
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        <p className="mb-0">
                                                                            Land Name: {user.locationId}
                                                                            <br />
                                                                            Project Name: {user.projectName}
                                                                            <br />
                                                                            Allocated Area: {user.areaAllocateToProject ? `${user.areaAllocateToProject} Beegha` : ''}
                                                                        </p>
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        No Of Plot: {user.noOfPlot}<br />
                                                                        No Of Shop: {user.noOfShop}<br />
                                                                        No Of Farm House: {user.noOfFarmHouse}
                                                                    </td>
                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        Created Date: {user.createdAt}<br />
                                                                        Created By: {user.creatorId}
                                                                    </td>

                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        Brochure:  <Link to={user.brochure} title="Brochure" target="__blanck" >
                                                                            <i className="fe fe-eye " style={{ cursor: 'pointer' }} />
                                                                        </Link>
                                                                        <br />
                                                                        Photo 1: <Link to={user.photo1} title="Photo 1" target="__blanck" >
                                                                            <i className="fe fe-eye " style={{ cursor: 'pointer' }} />
                                                                        </Link>
                                                                        <br />
                                                                        Photo 2: <Link to={user.photo2} title="Photo 2" target="__blanck" >
                                                                            <i className="fe fe-eye " style={{ cursor: 'pointer' }} />
                                                                        </Link>
                                                                        <br />
                                                                        Photo 3: <Link to={user.photo3} title="Photo 3" target="__blanck" >
                                                                            <i className="fe fe-eye " style={{ cursor: 'pointer' }} />
                                                                        </Link>
                                                                        <br />
                                                                        Photo 4: <Link to={user.photo4} title="Photo 4" target="__blanck" >
                                                                            <i className="fe fe-eye " style={{ cursor: 'pointer' }} />
                                                                        </Link>
                                                                        <br />
                                                                        Video: <Link to={user.videoLink} title="Video" target="__blanck" >
                                                                            <i className="fe fe-eye " style={{ cursor: 'pointer' }} />
                                                                        </Link>
                                                                    </td>

                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        <Link
                                                                            to='/inventory-view'
                                                                            type="button"
                                                                            className="btn ripple btn-xs mb-1"
                                                                            style={{
                                                                                backgroundColor: 'green',
                                                                                color: 'white',
                                                                                width: '70%', 
                                                                                transition: 'all 0.3s ease-in-out', 
                                                                            }}
                                                                            onMouseOver={(e) => {
                                                                                e.target.style.transform = 'scale(1.05)';
                                                                                e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                                                            }}
                                                                            onMouseOut={(e) => {
                                                                                e.target.style.transform = 'scale(1)';
                                                                                e.target.style.boxShadow = 'none';
                                                                            }}
                                                                        >
                                                                            Inventory
                                                                        </Link>

                                                                        <br />

                                                                        <Link
                                                                            to={`/add-eoi-plan/${user.id}`}
                                                                            type="button"
                                                                            className="btn ripple btn-xs mb-1"
                                                                            style={{
                                                                                backgroundColor: 'green',
                                                                                color: 'white',
                                                                                width: '70%', // Reduced width
                                                                                transition: 'all 0.3s ease-in-out', // Smooth transition
                                                                            }}
                                                                            onMouseOver={(e) => {
                                                                                e.target.style.transform = 'scale(1.05)';
                                                                                e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                                                            }}
                                                                            onMouseOut={(e) => {
                                                                                e.target.style.transform = 'scale(1)';
                                                                                e.target.style.boxShadow = 'none';
                                                                            }}
                                                                        >
                                                                            Add EOI Plan
                                                                        </Link>

                                                                        <br />

                                                                        <Link
                                                                            to={`/project-plan-view/${user.id}`}
                                                                            type="button"
                                                                            className="btn ripple btn-xs mb-1"
                                                                            style={{
                                                                                backgroundColor: 'green',
                                                                                color: 'white',
                                                                                width: '70%', // Reduced width
                                                                                transition: 'all 0.3s ease-in-out', // Smooth transition
                                                                            }}
                                                                            onMouseOver={(e) => {
                                                                                e.target.style.transform = 'scale(1.05)';
                                                                                e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                                                                            }}
                                                                            onMouseOut={(e) => {
                                                                                e.target.style.transform = 'scale(1)';
                                                                                e.target.style.boxShadow = 'none';
                                                                            }}
                                                                        >
                                                                            Eoi Plan View
                                                                        </Link>
                                                                    </td>

                                                                    <td style={{ whiteSpace: 'nowrap' }}>
                                                                        <button className="btn btn-sm btn-info me-2" onClick={() => loadContent(user.id)}>
                                                                            <span className="fe fe-edit"></span>
                                                                        </button>
                                                                        <button className="btn btn-sm btn-danger" onClick={() => deleteContent(user.id)}>
                                                                            <span className="fe fe-trash-2"></span>
                                                                        </button>
                                                                        <br />
                                                                        <br />
                                                                        <div className="main-toggle-group-demo" style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div
                                                                                className="main-toggle"
                                                                                onClick={() => handleToggle(user.id)}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    width: '60px',
                                                                                    height: '25px',
                                                                                    backgroundColor: toggleStates[user.id] ? '#4caf50' : '#ccc',
                                                                                    borderRadius: '25px',
                                                                                    position: 'relative',
                                                                                    transition: 'background-color 0.3s',
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        display: 'block',
                                                                                        width: '23px',
                                                                                        height: '23px',
                                                                                        backgroundColor: 'white',
                                                                                        borderRadius: '50%',
                                                                                        position: 'absolute',
                                                                                        top: '55%',
                                                                                        transform: 'translateY(-50%)',
                                                                                        left: toggleStates[user.id] ? '36px' : '1px',
                                                                                        transition: 'left 0.3s',
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-footer text-center">
                    <div className="container">
                        <div className="row row-sm">
                            <div className="col-md-12">
                                <span>
                                    Copyright © 2024 <a href="javascript:void(0)">AMRS</a>. Designed
                                    by <a href="http://webkype.com/">Webkype.com</a> All rights
                                    reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ManageEoi


